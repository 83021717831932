// import logo from './logo.svg';
import './App.css';

import { Route, Routes, useLocation } from 'react-router-dom';

import Home from './pages/Home'
import Header from './component/Header/Header'
import Footer from './component/Footer/Footer';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import Service from './pages/Service/Service';
import Testimonials from './pages/Testimonials/Testimonials';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Gdpr from './pages/Gdpr';
import Cookie from './pages/Cookie';
import AcceptTnC from './pages/AcceptTnC';
import DetailsTwo from './pages/DetailsTwo';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Gallary from './pages/Gallary/Gallary';


function App() {
  const location = useLocation();

  return (
    <div className="App" >
      <Header />
      <Routes>
        {/* {
            location.pathname==='/'
          } */}
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about-us' element={<AboutUs />} />
        <Route exact path='/contact-us' element={<ContactUs />} />
        <Route exact path='/service' element={<Service />} />
        <Route exact path='/testimonial' element={<Testimonials />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/gdpr' element={<Gdpr />} />
        <Route path='/cookie' element={<Cookie />} />
        <Route path='/page-one' element={<AcceptTnC />} />
        <Route path='/page-two' element={<DetailsTwo />} />
      </Routes>

      <Footer />

    </div>
  );
}

export default App;
