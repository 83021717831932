import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../../css/header.css";
import React from "react";
import JoinPanelPopUp from "../popupbox/JoinPanelPopUp";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
const Header = () => {
  console.log("window", window.location.pathname);
  const location = useLocation();
  console.log("react", location.pathname);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  function navigateFunc() {
    window.location.href = "/";
  }

  const drawer = (
    <Box sx={{ textAlign: "center" }} className="sidedrawer">
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "20px",
          color: "#876550",
          justifyContent: "space-around",
          marginTop: "50px",
          flexWrap: "wrap",
          gap: "10px",
        }}
        className="d-flex m-auto "
        variant="h4"
        component="div"
      >
        <NavLink to="/">
          <span className="side-nav-logo-first-text">CALC</span>
          <span className="side-nav-logo-last-text">INSIGHTS</span>
        </NavLink>
        <JoinPanelPopUp handleDrawerToggle={handleDrawerToggle} />
      </Typography>

      <List>
        <ListItem>
          {/* <ListItemButton> */}
          <ListItemText className="menuList">
            <NavLink onClick={navigateFunc}>
              <Typography onClick={handleDrawerToggle}>Home</Typography>
            </NavLink>
            {/* <Divider /> */}
            <NavLink to="/about-us" onClick={handleDrawerToggle}>
              <Typography>About us</Typography>
            </NavLink>
            {/* <Divider /> */}

            {/* <Divider/> */}

            <NavLink to="/service" onClick={handleDrawerToggle}>
              <Typography>Service</Typography>
            </NavLink>
            <NavLink to="/contact-us" onClick={handleDrawerToggle}>
              <Typography>Contact us</Typography>
            </NavLink>
          </ListItemText>

          {/* </ListItemButton> */}
        </ListItem>
      </List>
    </Box>
  );
  return (
    <>
      {!(
        location.pathname.includes("page-one") ||
        location.pathname.includes("page-two")
      ) && (
        <header id="pxl-header-elementor" className="is-sticky">
          <div className="pxl-header-elementor-main px-header--default">
            <div className="pxl-header-content">
              <div className="row">
                <div className="col-12">
                  <div
                    data-elementor-type="wp-post"
                    data-elementor-id="2778"
                    className="elementor elementor-2778"
                  >
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-092fdbf elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                      data-id="092fdbf"
                      data-element_type="section"
                      data-settings='{"background_background":"classNameic"}'
                    >
                      <div className="elementor-container elementor-column-gap-default">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-eba225f pxl-column-none"
                          data-id="eba225f"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-db30879 elementor-widget__width-auto pxl-flex-grow elementor-widget elementor-widget-pxl_icon"
                              data-id="db30879"
                              data-element_type="widget"
                              data-widget_type="pxl_icon.default"
                            >
                              <div className="elementor-widget-container">
                                <div
                                  className="pxl-icon-list pxl-icon1 style-default"
                                  data-wow-delay="ms"
                                >
                                  <NavLink
                                    className="elementor-repeater-item-923dd4c ps-top"
                                    to="https://www.facebook.com/profile.php?id=100093279026469"
                                  >
                                    <i
                                      aria-hidden="true"
                                      className="fab fa-facebook-f"
                                    ></i>
                                  </NavLink>
                                  <NavLink
                                    className="elementor-repeater-item-ab0a4b9 ps-top"
                                    to="https://www.instagram.com/calcinsights/"
                                  >
                                    <i
                                      aria-hidden="true"
                                      className="fab fa-instagram"
                                    ></i>
                                  </NavLink>
                                  <NavLink
                                    className="elementor-repeater-item-7e3ed51 ps-top"
                                    to="https://twitter.com/CalcInsights"
                                  >
                                    <i
                                      aria-hidden="true"
                                      className="fab fa-twitter"
                                    ></i>
                                  </NavLink>
                                  <NavLink
                                    className="elementor-repeater-item-07a1746 ps-top"
                                    to="https://www.linkedin.com/company/calc-insights/"
                                  >
                                    <i
                                      aria-hidden="true"
                                      className="fab fa-linkedin-in"
                                    ></i>
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-ee6f36e elementor-widget__width-auto elementor-widget elementor-widget-pxl_link"
                              data-id="ee6f36e"
                              data-element_type="widget"
                              data-widget_type="pxl_link.default"
                            >
                              <div className="elementor-widget-container">
                                <ul
                                  id="pxl-link-pxl_link-ee6f36e-4064"
                                  className="pxl-link pxl-link-l1 style-default type-vertical"
                                  data-wow-delay="ms"
                                >
                                  <li>
                                    <NavLink href="mailto:contact@jackcerra.com">
                                      <i
                                        aria-hidden="true"
                                        className="flaticon flaticon-mail"
                                      ></i>
                                      <span>info@calcinsights.com</span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-e887ba1 elementor-widget__width-auto elementor-widget elementor-widget-pxl_link"
                              data-id="e887ba1"
                              data-element_type="widget"
                              data-widget_type="pxl_link.default"
                            >
                              <div className="elementor-widget-container">
                                <ul
                                  id="pxl-link-pxl_link-e887ba1-5711"
                                  className="pxl-link pxl-link-l1 style-default type-vertical"
                                  data-wow-delay="ms"
                                >
                                  <li>
                                    <NavLink href="#">
                                      <i
                                        aria-hidden="true"
                                        className="fas fa-map-marker-alt"
                                      ></i>
                                      <span>New Delhi</span>
                                    </NavLink>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-09e8cf5 elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                      data-id="09e8cf5"
                      data-element_type="section"
                      data-settings='{"stretch_section":"section-stretched","background_background":"classNameic"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5688117 pxl-column-none"
                          data-id="5688117"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-fb6a177 elementor-widget__width-auto pxl-flex-grow elementor-widget elementor-widget-pxl_logo"
                              data-id="fb6a177"
                              data-element_type="widget"
                              data-widget_type="pxl_logo.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="pxl-logo" data-wow-delay="ms">
                                  <NavLink to="/">
                                    <p style={{ margin: "auto" }}>
                                      <span
                                        className="logo-first-text"
                                        style={{
                                          color:
                                            location.pathname === "/"
                                              ? "#fff"
                                              : "#000",
                                        }}
                                      >
                                        CALC
                                      </span>
                                      <span className="logo-last-text">
                                        INSIGHTS
                                      </span>
                                    </p>
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-e53848b elementor-widget__width-auto elementor-widget elementor-widget-pxl_menu"
                              data-id="e53848b"
                              data-element_type="widget"
                              data-widget_type="pxl_menu.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="pxl-nav-menu pxl-nav-menu1 pxl-mega-boxed fr-style-default show-effect-slidedown sub-style-default">
                                  <div className="menu-main-menu-container d-flex align-items-center">
                                    <ul
                                      id="menu-main-menu"
                                      className="pxl-menu-primary clearfix links-hover"
                                    >
                                      <li
                                        id="menu-item-33"
                                        className="menu-item menu-item-type-custom menu-item-object-custom pxl-megamenu menu-item-has-children menu-item-33 navLinkMenu"
                                      >
                                        {/* <NavLink to='/' onClick={window.location.href}> */}
                                        <div
                                          onClick={navigateFunc}
                                          className="home"
                                        >
                                          <span
                                            style={{
                                              color:
                                                location.pathname === "/"
                                                  ? "#FFF"
                                                  : "#000",
                                              ":hover": { color: "red" },
                                            }}
                                          >
                                            Home
                                          </span>
                                        </div>
                                        {/* </NavLink> */}
                                      </li>
                                      <li
                                        id="menu-item-61"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-61"
                                      >
                                        <NavLink to="/about-us">
                                          <span
                                            style={{
                                              color:
                                                location.pathname === "/"
                                                  ? "#FFF"
                                                  : "#000",
                                            }}
                                          >
                                            About Us
                                          </span>
                                        </NavLink>
                                      </li>

                                      <li
                                        id="menu-item-63"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-63"
                                      >
                                        <NavLink to="/service">
                                          <span
                                            style={{
                                              color:
                                                location.pathname === "/"
                                                  ? "#FFF"
                                                  : "#000",
                                            }}
                                          >
                                            Service
                                          </span>
                                        </NavLink>
                                      </li>
                                      <li
                                        id="menu-item-62"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-62"
                                      >
                                        <NavLink to="/contact-us">
                                          <span
                                            style={{
                                              color:
                                                location.pathname === "/"
                                                  ? "#FFF"
                                                  : "#000",
                                            }}
                                          >
                                            Contact Us
                                          </span>
                                        </NavLink>
                                      </li>
                                      <li
                                        id="menu-item-34"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-34"
                                      ></li>
                                    </ul>
                                    <JoinPanelPopUp />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pxl-header-elementor-sticky pxl-onepage-sticky pxl-sticky-stt">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    data-elementor-type="wp-post"
                    data-elementor-id="2780"
                    className="elementor elementor-2780"
                  >
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-1104efe elementor-section-content-middle elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default pxl-row-scroll-none pxl-bg-color-none pxl-section-overlay-none"
                      data-id="1104efe"
                      data-element_type="section"
                      data-settings='{"stretch_section":"section-stretched","background_background":"classNameic"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e545448 pxl-column-none"
                          data-id="e545448"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-9c9859e elementor-widget__width-auto pxl-flex-grow elementor-widget elementor-widget-pxl_logo"
                              data-id="9c9859e"
                              data-element_type="widget"
                              data-widget_type="pxl_logo.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="pxl-logo" data-wow-delay="ms">
                                  <NavLink to="/">
                                    <p>
                                      <span
                                        className="logo-first-text"
                                        style={{ color: "#fff" }}
                                      >
                                        CALC
                                      </span>
                                      <span className="logo-last-text">
                                        INSIGHTS
                                      </span>
                                    </p>
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-7a81658 elementor-widget__width-auto elementor-widget elementor-widget-pxl_menu"
                              data-id="7a81658"
                              data-element_type="widget"
                              data-widget_type="pxl_menu.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="pxl-nav-menu pxl-nav-menu1 pxl-mega-boxed fr-style-default show-effect-slidedown sub-style-default">
                                  <div className="menu-main-menu-container d-flex align-items-center">
                                    <ul
                                      id="menu-main-menu"
                                      className="pxl-menu-primary "
                                    >
                                      <li
                                        id="menu-item-33"
                                        className="menu-item menu-item-type-custom menu-item-object-custom pxl-megamenu menu-item-has-children menu-item-33"
                                      >
                                        <NavLink to="/">
                                          <span style={{ color: "#fff" }}>
                                            Home
                                          </span>
                                        </NavLink>
                                      </li>
                                      <li
                                        id="menu-item-61"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-61"
                                      >
                                        <NavLink to="/about-us">
                                          <span style={{ color: "#fff" }}>
                                            About Us
                                          </span>
                                        </NavLink>
                                      </li>

                                      <li
                                        id="menu-item-63"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-63"
                                      >
                                        <NavLink to="/service">
                                          <span style={{ color: "#fff" }}>
                                            Service
                                          </span>
                                        </NavLink>
                                      </li>
                                      <li
                                        id="menu-item-62"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-62"
                                      >
                                        <NavLink to="/contact-us">
                                          <span style={{ color: "#fff" }}>
                                            Contact Us
                                          </span>
                                        </NavLink>
                                      </li>
                                      <li
                                        id="menu-item-34"
                                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-34"
                                      ></li>
                                    </ul>
                                    <JoinPanelPopUp />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="pxl-header-mobile" className="style-dark">
            <div
              id="pxl-header-main"
              className="pxl-header-main"
              style={{ backgroundColor: "#112640" }}
            >
              <div className="container">
                <div className="row">
                  <div className="pxl-header-branding">
                    <NavLink to="/">
                      <p className="logo-p">
                        <span
                          className="logo-first-text"
                          style={{ color: "#fff" }}
                        >
                          CALC
                        </span>
                        <span className="logo-last-text">INSIGHTS</span>
                      </p>
                    </NavLink>
                  </div>

                  <div id="pxl-nav-mobile">
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{
                        mr: 2,
                        display: { sm: "none" },
                        display: "block !important",
                        justifyContent: "flex-start",
                        marginLeft: "5px",
                        width: "5%",
                      }}
                    >
                      <MenuIcon sx={{ color: "white", fontSize: "35px" }} />
                    </IconButton>

                    <Box component="nav">
                      <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                          keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                          display: { xs: "block", sm: "block" },

                          "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: { xs: "100%", sm: "500px", md: "500px" },
                            backgroundColor: "#1c2539",
                          },
                        }}
                        className="side-drawer-main"
                      >
                        {drawer}
                      </Drawer>
                    </Box>
                  </div>
                  <div className="pxl-header-menu-backdrop"></div>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
