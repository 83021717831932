import React, { useEffect } from 'react'
import styles from "./acceptTnC.module.css"
import { NavLink, useSearchParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import axios from 'axios';

function AcceptTnC() {
    const [searchParams] = useSearchParams();
    let mode = searchParams.get("mode")
    let hash = searchParams.get("hash")
    let uid = searchParams.get("uid")

    useEffect(() => {
        if (!mode && !hash && !uid) {
            window.location.href = "/"
        }
        console.log(mode, hash, uid)
    }, [])

    useEffect(() => {
        if (mode === "test") {
            handleResponse()
        }
    }, [mode])

    const handleResponse = async () => {
        try {
            let response = await axios.post(`${process.env.REACT_APP_API_AUTH_URL}/api/survey`, {
                uid: uid,
                age: 0,
                gender: "",
                mode: mode,
                hash: hash
            })
            window.location.href = response.data.link
        } catch (error) {
            alert("Please try again!")
        }
    }

    return (
        <div className={styles["main-container"]}>
            <div 
            className='side-nav-logo-last-text'
            onClick={() => {
                window.open("https://calcinsights.com","_target")
            }} style={{
                position: "absolute",
                top: "50px",
                right: "30px",
                fontSize: "24px",
                cursor: "pointer"
            }}>Home</div>
            <div className={styles["container"]}>
                <NavLink to="/">
                    <span className="side-nav-logo-first-text" style={{
                        color: "#000"
                    }}>CALC</span>
                    <span className="side-nav-logo-last-text">INSIGHTS</span>
                </NavLink>
                <p><strong>Greetings! A warn welcome to you!</strong></p>

                <p style={{
                    textAlign: "left"
                }}>You've been selected for a special mission by us, a renowned market research organization. Our goal? To understand your world a little better!</p>

                <p style={{
                    textAlign: "left"
                }}>On this unique journey, you'll share your views anonymously. Note that some questions marked with a star (*) are mandatory!</p>

                <p style={{
                    textAlign: "left"
                }}>Before we begin, we need you to agree to our guidelines: </p>

                <ul style={{
                    textAlign: "left"
                }}>
                    <li><NavLink to="/privacy" target='_blank'>Privacy Policy</NavLink> - Your data is our treasure, handled with utmost care.</li>
                    <li><NavLink to="/terms" target='_blank'>Terms of Use</NavLink> - Your guide to navigating this survey.</li>
                    <li><NavLink to="/cookie" target='_blank'>Cookie Policy</NavLink> - The trails we follow.</li>
                    <li><NavLink to="/gdpr" target='_blank'>GDPR Policy</NavLink> - Ensuring your data rights are protected</li>
                </ul>

                <p style={{
                    textAlign: "left"
                }}>By agreeing, you accept that your data is safe with us</p>

                <p style={{
                    textAlign: "left"
                }}>Thank You!</p>

                <div style={{
                    display: "flex",
                    gap: "20px"
                }}>
                    <Button style={{padding:"0px"}}><NavLink to={`/page-two?mode=${mode}&hash=${hash}&uid=${uid}`} style={{ color: "#FFF" }}><span style={{padding:"0px 20px"}}>Agree</span></NavLink></Button>
                    <Button style={{ backgroundColor: "blue", color: "#000", padding:"0px" }}><div onClick={()=>window.location.href=`${process.env.REACT_APP_API_AUTH_URL}/api/render-complete-page?status=6`} style={{ color: "#FFF" }}><span style={{padding:"0px 20px"}}>Disagree</span></div></Button>
                </div>

            </div>
        </div >
    )
}

export default AcceptTnC